import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/0-layout"
import Images from "../components/image"
import Query from "../components/query"
import SEO from "../components/seo"
import "../scss/android.scss"

/**
 * @property {object} allAndroidJson
 * @property {object} introduction
 */

const AndroidPage = () => {
  const data = Query()

  const android = useStaticQuery(graphql`
      query AndroidQuery {
          allAndroidJson {
              edges {
                  node {
                      introduction
                      order
                      links {
                          li
                          a
                          href
                      }
                  }
              }
          }
      }
  `)

  let items = [], cnt = 1, a = []
  android.allAndroidJson.edges[1].node.order.forEach(i => {
    if (cnt === 1) {
      android.allAndroidJson.edges[2].node.links.forEach(j => {
        a.push(
          <li class={"link"}>
            {j.li}<br/>
            <a href={j.href}>{j.a}</a>
          </li>,
        )
      })
    } else {
      a = null
    }

    items.push(
      <li className={"order"}>
        <b>{i}</b>

        <ul>{a}</ul>

        <div>
          <Images filename={"android/" + cnt + ".jpg"} alt={i}/>
        </div>
      </li>,
    )

    cnt = cnt + 1
  })

  return (
    <Layout sitePath={"android"} pageTitle={data.site.siteMetadata.android}>

      <SEO title={data.site.siteMetadata.android}/>

      <h1 className={"start"}>
        {android.allAndroidJson.edges[0].node.introduction[0]}
      </h1>

      <Images filename={"android/0.png"} alt={android.allAndroidJson.edges[0].node.introduction[1]}/>

      <p className={"introduction"}>
        {android.allAndroidJson.edges[0].node.introduction[1]}
      </p>

      <ol>
        {items}
      </ol>

    </Layout>
  )
}

export default AndroidPage
